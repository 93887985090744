import React from 'react';

const HeadingRenderer = (props) => {
  const { level, children } = props;

  switch (level) {
    case 1:
      return <h2>{children}</h2>;
    case 2:
      return <h3>{children}</h3>;
    case 3:
      return <h4>{children}</h4>;
    case 4:
      return <h5>{children}</h5>;
    case 5:
      return <h6>{children}</h6>;
    case 6:
      return <h6>{children}</h6>;
    default:
      return <h2>{children}</h2>;
  }
};

export default HeadingRenderer;
